// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Instruments
import styles from './main-sections.module.scss';
import Container from '../../../layouts/container/container';
import VerticalLines from '../../../components/vertical-lines/ vertical-lines';

const MainSection = ({ activeSection, title, data }) => {
  const [sectionLoaded, setSectionLoaded] = useState(false);

  useEffect(() => {
    setSectionLoaded(true);
  }, []);

  return (
    <section data-active={`active_${sectionLoaded ? activeSection : 0}`} className={`${styles.mainSection} ${styles.sections}`}>
      <div className={styles.mainSectionOverlay} style={{ backgroundImage: `url(${data.image.url})` }} />
      <VerticalLines startAnimation={sectionLoaded} />
      <Container>
        <div className={styles.animatedTextContainer}>
          <div className={styles.animatedText}>
            <div className={styles.page_title}>{title}</div>
          </div>
          <div className={styles.animatedText}>
            <h1 className={styles.h2}>{data.title}</h1>
          </div>
          <div className={styles.animatedText}>
            <h1 className={`${styles.h2} ${styles.h2_bold}`}>{data.titleBold}</h1>
          </div>
          {/* <div className={styles.animatedText}>
            <h1 className={styles.h2}>
              tempor
              <span className={styles.h2_bold}> suspendisse dictum.</span>
            </h1>
          </div> */}
        </div>
      </Container>
    </section>
  );
};

MainSection.propTypes = {
  activeSection: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,

};

export default MainSection;
