// Core
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ScrollAnimation from 'react-animate-on-scroll';

// Instruments
import styles from './releases-section.module.scss';
import CarouselBlock from '../../../components/carousel/carousel';
import Icon from '../../../static/images/PDF.png';
import Container from '../../../layouts/container/container';
import VerticalLines from '../../../components/vertical-lines/ vertical-lines';

const ReleasesSection = ({
  activeSection, title, index, setCanScrollDown, setCanScrollUp, data, isMobile,
}) => {
  const [currentNumber, setCurrentNumber] = useState(1);
  useEffect(() => {
    const div = document.querySelector(`.slick-slide.slick-active.slick-current .${styles.animatedTextContainer}`);
    const hasVerticalScrollbar = div.scrollHeight > div.clientHeight;
    if (hasVerticalScrollbar) {
      div.addEventListener('mouseover', () => {
        setCanScrollDown(true);
        setCanScrollUp(true);
      });
      div.addEventListener('mouseleave', () => {
        setCanScrollDown(false);
        setCanScrollUp(false);
      });
    }
  }, [currentNumber]);
  const setNumber = (num) => {
    setCurrentNumber(num);
  };
  const dataActive = `active_${activeSection}`;
  const renderSection = () => (
    <section data-active={dataActive} className={`current-${index} ${styles.releasesSection} ${styles.sections} v4`}>
      <VerticalLines />
      <h3 className={`${styles.sectionTitle} ${styles.h3}`}>{`${title}`}</h3>
      <CarouselBlock
        getCurrentNumber={setNumber}
        index={index}
      >
        {data.map((slideData) => (
          <div className={styles.slide}>
            <Container>
              <div className={`${styles.animatedTextContainer}`}>
                <div className={styles.releasesSectionRow}>
                  {slideData.map((fileData) => {
                    if (fileData) {
                      return (
                        // eslint-disable-next-line react/jsx-no-target-blank
                        <a href={fileData.url} className={`animatedSliderText ${styles.p_decription}`} target="_blank">
                          <img className={styles.icon} src={Icon} alt="" />
                          <p>{fileData.title}</p>
                          <p>{fileData.caption}</p>
                        </a>
                      );
                    }
                    return <></>;
                  })}
                </div>
              </div>
            </Container>
          </div>
        ))}
      </CarouselBlock>

    </section>
  );
  return (
    isMobile
      ? (
        <ScrollAnimation
          animateOnce
          initiallyVisible
          animateIn={`${styles.animateIn} animate-in`}
        >
          {renderSection()}
        </ScrollAnimation>
      ) : renderSection()
  );
};
ReleasesSection.propTypes = {
  activeSection: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  setCanScrollDown: PropTypes.func.isRequired,
  setCanScrollUp: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default ReleasesSection;
